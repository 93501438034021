import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";

import "../TradingBot/index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";

import { web3 } from "../../store/web3";
import { networkId, message } from "../../store/config";
import { toggleLoader, login } from "../../store/actions/Auth";

class TradingBot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      redirect: false,
    };
  }

  componentWillReceiveProps({ publicAddress }) {
    this.setState({ address: publicAddress })
  }

  connectWallet = async () => {
    if (typeof window.ethereum === 'undefined') {
      EventBus.publish('error', `Please Install Wallet!!!`);
      return;
    }

    web3.eth.net.getId(async (err, netId) => {
      if (netId != networkId) {
        EventBus.publish('info', message);
        return;
      }
      let address = (await web3.currentProvider.enable())[0];
      address = address.toLowerCase()
      this.props.login({address});
      this.setState({ address, redirect: true });
      // window.location.href = '/Dashboard'
    });
  };

  render() {
    let { sticky, publicAddress } = this.props;
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        {/* Header Start  */}
        <header>
          <div class="container">
            <nav class="navbar navbar-expand-lg">
              <a class="logo" href="">
                <img src="images/logo.png" alt="" />
              </a>
            </nav>
          </div>
        </header>
        {/* Header End  */}

        {/* Content Start  */}
        <section class="signin-main">
          <div class="container">
            <div class="inner">
              <h1>Sign in</h1>

              <div class="form-group">
                <div class="input-group" id="show_hide_password">
                  <input type="text" class="form-control" placeholder="Enter wallet key" disabled value={publicAddress['address']} />
                </div>
              </div>
              {/* <button onClick={this.connectWallet}>{publicAddress['address'] == '' || publicAddress['address'] == null
                ? 'Sign in'
                : publicAddress['address'] && publicAddress['address'].substring(0, 5) + '.....' + publicAddress['address'].substring(37, publicAddress.length)
              }</button> */}
              <button onClick={this.connectWallet}> Sign in </button>
              
            </div>
          </div>
        </section>
        {/* Content End  */}
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader, login };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress } = Auth;
  console.log("******************* publicAddress",publicAddress['address'])
  return { publicAddress };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradingBot);
