import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Link, useHistory } from "react-router-dom";
import { setStyleButtons } from '../../store/actions/Auth';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            address: '',
            dash: true,
            hist: false,
        };
    };

    handleDash = async(status) => {
        this.props.setStyleButtons(status)
    }

    logout = async() =>{
        localStorage.removeItem('token');
        window.location.href = '/'
    }

    render() {
        let { styleButton } = this.props;
        return (
            <div class="wrapper">
                <header>
                    <nav class="navbar navbar-expand-lg">
                        <Link class="logo" to="/">
                            <img src="images/logo.png" alt="" />
                        </Link>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav">
                                        <li class={window.location.pathname == "/Dashboard" ? "nav-item active" : "nav-item" }>
                                            <Link to="/Dashboard" class="nav-link" onClick={()=>this.handleDash(false)} >Trading Bot</Link>
                                        </li>
                                        <li class={window.location.pathname == "/History" ? "nav-item active" : "nav-item" }>
                                            <Link to="/History" class="nav-link" onClick={()=>this.handleDash(true)} >Trading Bot History</Link>
                                        </li>
                                        <li class={window.location.pathname == "/Marketmaking" ? "nav-item active" : "nav-item" }>
                                            <Link to="/Marketmaking" class="nav-link" >Market Making Bot</Link>
                                        </li>
                                        <li class={window.location.pathname == "/MarketHistory" ? "nav-item active" : "nav-item" }>
                                            <Link to="/MarketHistory" class="nav-link" >Market Bot History</Link>
                                        </li>
                            </ul>
                        </div>
                        <div class="logedin-user">
                            <Link to="#" onClick={()=>this.logout()}>
                                <span>Logout</span>
                            </Link>
                        </div>
                    </nav>
                    <div class="navbar-mb-wrap">
                        <ul class="navbar-mb">
                                        <li class={window.location.pathname == "/Dashboard" ? "nav-item active" : "nav-item" }>
                                            <Link to="/Dashboard" class="nav-link" onClick={()=>this.handleDash(false)} >Trading Bot</Link>
                                        </li>
                                        <li class={window.location.pathname == "/History" ? "nav-item active" : "nav-item" }>
                                            <Link to="/History" class="nav-link" onClick={()=>this.handleDash(true)} >Trading Bot History</Link>
                                        </li>
                                        <li class={window.location.pathname == "/Marketmaking" ? "nav-item active" : "nav-item" }>
                                            <Link to="/Marketmaking" class="nav-link" >Market Making Bot</Link>
                                        </li>
                                        <li class={window.location.pathname == "/MarketHistory" ? "nav-item active" : "nav-item" }>
                                            <Link to="/MarketHistory" class="nav-link" >Market Bot History</Link>
                                        </li>
                        </ul>
                    </div>
                </header>
            </div>
        );
    }
}

const mapDispatchToProps = {
    setStyleButtons
};

const mapStateToProps = ({ Auth }) => {
    let { styleButton } = Auth;
    return { styleButton }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);