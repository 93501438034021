import React from 'react';
import { connect } from 'react-redux';

class Timer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
    let getToken = localStorage.getItem("token");
    if(!getToken){
        window.location.href = '/';
    }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    return(<div></div>);
  }
}

// export default Timer;
const mapDispatchToProps = { };

const mapStateToProps = ({ Auth }) => {
  let {publicAddress} = Auth;
  return {publicAddress};
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);