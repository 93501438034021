import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Error from '@material-ui/icons/Error';
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import TradingBot from './TradingBot';
import Dashboard from './Dashboard';
import History from './History';
import Loader from '../components/loader';
import { web3 } from "../store/web3";
import { networkId, message } from "../store/config";
import { logout, login } from '../store/actions/Auth';
import history from '../history';
import Marketmaking from './Marketmaking';
import MarketHistory from "./MarketHistory"
import '../static/css/style.css';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  };

  componentDidMount() {
    this.checkEthereum();
    EventBus.on('tokenExpired', () => this.props.logout());
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  };


  checkEthereum = async () => {

    if (typeof window.ethereum !== "undefined") {
      // check network
      web3.eth.net.getId((err, netId) => {
        if (netId != networkId) {
          EventBus.publish('info', message);
        }
      });
    }
  };


  render() {

    return (
      <div>
        {/* <Timer /> */}
        <Loader />
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />

        <Router history={history}>
          <Switch>
            <Route exact path='/' component={props => <TradingBot {...props} />} />
            <Route path='/TradingBot' component={props => <TradingBot {...props} />} />
            <Route path='/Dashboard' component={props => <Dashboard {...props} />} />
            <Route path='/History' component={props => <History {...props} />} />
            <Route path='/Marketmaking' component={props => <Marketmaking {...props} />} />
            <Route path='/MarketHistory' component={props => <MarketHistory {...props} />} />
          </Switch>
        </Router>

      </div>
    );
  }
}

const mapDispatchToProps = {
  logout, login
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress } = Auth;
  return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);