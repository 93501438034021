import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import {Redirect} from 'react-router-dom';
import "../TradingBot/index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import {saveExchange} from "../../store/actions/Auth"
import Navbar from "../../components/navbar";
import {toggleLoader,stopBot } from "../../store/actions/Auth";
import Timer from "../resetProvider";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      address: localStorage.getItem("publicAddress"),
      pair:"XDC/USDT",
      exchange1:"bybit",
      exchange2:"bybit",
      exchange1ApiKey:"",
      exchange1Secret:"",
      exchange2ApiKey:"",
      exchange2Secret:"",
      kuCoinPassphrase1:"",
      kuCoinPassphrase2:"",
      amount:"",
      numberOfTradePerDay:1000,
      defaultExchangeType:"USDT",
      exchanges:[]
    };
  }

  changePairsData=(e)=>{
    this.setState({pair:e.target.value})
    if(e.target.value == "XDC/ETH"){
      this.setState({defaultExchangeType:"ETH",exchange1:"bitrue",exchange2:"bitrue"})
    }else if(e.target.value == "XDC/BTC"){
      this.setState({defaultExchangeType:"BTC",exchange1:"coinex",exchange2:"coinex"})
    }else if(e.target.value == "XDC/USDT"){
      this.setState({defaultExchangeType:"USDT",exchange1:"bybit",exchange2:"bybit"})
    }
  }

  startBot =()=>{
    let {exchanges,pair,exchange1,exchange2,exchange1ApiKey,exchange1Secret,exchange2ApiKey,exchange2Secret,amount,numberOfTradePerDay,kuCoinPassphrase1,kuCoinPassphrase2} = this.state;

    if(!pair) return EventBus.publish("error", "Please Select Pair");
    if(!exchange1) return EventBus.publish("error", "Please Select Exchange 1");
    if(!exchange2) return EventBus.publish("error", "Please Select Exchange 2");
    if(!exchange1ApiKey) return EventBus.publish("error", "Please Add Api Key Exchange 1");
    if(!exchange2ApiKey) return EventBus.publish("error", "Please Add Api Key Exchange 2");
    if(!exchange1Secret) return EventBus.publish("error", "Please Add Secret Key Exchange 1");
    if(!exchange2Secret) return EventBus.publish("error", "Please Add Secret Key Exchange 2");
    if(!amount || amount < 39) return EventBus.publish("error", "Minimum Amount 40 XDC");
    if(!numberOfTradePerDay) return EventBus.publish("error", "Please Add Number Of Trade Per Day");

    if(exchange1 == "kucoin" || exchange1 == 'bitget') {
      if(!kuCoinPassphrase1) return EventBus.publish("error", "Please Add Pass Phrase");
    }

    if(exchange2 == "kucoin" || exchange2 == 'bitget') {
      if(!kuCoinPassphrase2) return EventBus.publish("error", "Please Add Pass Phrase");
    }

    for (let index = 0; index < exchanges.length; index++) {
      const element = exchanges[index];
      if(!element['exchange']) return EventBus.publish("error", `Exchange ${index + 3} name is empty`);
      if(!element['apiKey']) return EventBus.publish("error", `${element['exchange']} api key is empty`);
      if(!element['secret']) return EventBus.publish("error", `${element['exchange']} secret is empty`);
    }
    
    exchanges = [...exchanges,{exchange:exchange1,apiKey:exchange1ApiKey,secret:exchange1Secret,kuCoinPassphrase:kuCoinPassphrase1},{exchange:exchange2,apiKey:exchange2ApiKey,secret:exchange2Secret,kuCoinPassphrase:kuCoinPassphrase2}];
    this.props.toggleLoader({message:"Verify Keys & Execute",status:true});
    this.props.saveExchange({exchanges,pair,amount,numberOfTradePerDay});
  }

  handleChange = (i, e) => {
    let {exchanges} = this.state;
    let newFormValues = [...exchanges];
    newFormValues[i][e.target.name] = e.target.value;
 }

  addInputField = ()=>{
    let {exchanges} = this.state;
    if(exchanges.length >= 3) return EventBus.publish("error", "You can select maximum 5 exchanges");
    this.setState({exchanges:[...exchanges, {
        exchange:'',
        apiKey:'',
        secret:''  
    }]})
  }

  removeFormFields = (index)=>{
    let {exchanges} = this.state;
    const rows = [...exchanges];
    rows.splice(index, 1);
    this.setState({exchanges:rows})
  }

  handleChange = (index, evnt)=>{
    let {exchanges} = this.state;
    const { name, value } = evnt.target;
    const list = [...exchanges];
    list[index][name] = value;
    this.setState({exchanges:list})
}

 stop =( ) =>{
  this.props.toggleLoader({message:"Bot Stop Please Wait....",status:true});
  this.props.stopBot();
 }

  render() {
    let { sticky,defaultExchangeType,exchanges,exchange1,exchange2 } = this.state;
    let token = localStorage.getItem('token');
    
    if(!token) return <Redirect to="/" />
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        <Navbar sticky={sticky} />
        <Timer />
        {/* Content Start */}
        <section class="dashboard">
            <div class="container">
                <div class="inner">
                    <div className="form">
                        <div class="form-group">
                            <h5>Select trading pair</h5>
                            <select class="form-control" onChange={e=>this.changePairsData(e)}>
                            <option value="XDC/USDT">XDC/USDT</option>
                            <option value="XDC/ETH">XDC/ETH</option>
                            <option value="XDC/BTC">XDC/BTC</option>
                            </select>
                        </div>

                        <div class="form-group">
                             <h5>Exchange 1</h5>
                            {defaultExchangeType == "USDT" &&
                              <select class="form-control" onChange={e=>this.setState({exchange1:e.target.value})}>
                                <option value="bybit">Bybit</option>
                                <option value="bitrue">Bitrue</option>
                                <option value="coinex">CoinEx</option>
                                <option value="hitbtc">Hitbtc</option>
                                {/* <option value="digifinex">digifinex</option> */}
                                {/* <option value="coinw">coinw</option> */}
                                <option value="bkex">Bkex</option>
                                <option value="whitebit">Whitebit</option>
                                {/* <option value="bitmart">bitmart</option> */}
                                {/* <option value="coindcx">coindcx</option> */}
                                <option value="bitget">Bitget</option>
                                <option value="kucoin">Kucoin</option>
                              </select>
                            }
                            {defaultExchangeType == "BTC" &&
                              <select class="form-control" onChange={e=>this.setState({exchange1:e.target.value})}>
                                <option value="coinex">CoinEx</option>
                                <option value="hitbtc">Hitbtc</option>
                                {/* <option value="digifinex">digifinex</option> */}
                                <option value="kucoin">Kucoin</option>
                              </select>
                            }
                            {defaultExchangeType == "ETH" &&
                              <select class="form-control" onChange={e=>this.setState({exchange1:e.target.value})}>
                                <option value="bitrue">Bitrue</option>
                                <option value="hitbtc">Hitbtc</option>
                                {/* <option value="digifinex">digifinex</option> */}
                                <option value="whitebit">Whitebit</option>
                                <option value="kucoin">Kucoin</option>
                              </select>
                            }
                          {exchange1 == "kucoin"  || exchange1 == "bitget" ?
                            <div class="inputgroup">
                                <label></label>
                                <input type="text" class="form-control" placeholder="Enter API key" onChange={e=>this.setState({exchange1ApiKey:e.target.value})}/>
                                <input type="text" class="form-control" placeholder="Enter secret key"  onChange={e=>this.setState({exchange1Secret:e.target.value})}/>
                                <input type="text" class="form-control" placeholder="Enter Pass Phrase"  onChange={e=>this.setState({kuCoinPassphrase1:e.target.value})}/>
                            </div>
                          :
                          <div class="inputgroup">
                                <label></label>
                                <input type="text" class="form-control" placeholder="Enter API key" onChange={e=>this.setState({exchange1ApiKey:e.target.value})}/>
                                <input type="text" class="form-control" placeholder="Enter secret key"  onChange={e=>this.setState({exchange1Secret:e.target.value})}/>
                          </div>
                        }

                            <h5>Exchange 2</h5>

                            {defaultExchangeType == "USDT" &&
                              <select class="form-control" onChange={e=>this.setState({exchange2:e.target.value})}>
                                <option value="bybit">Bybit</option>
                                <option value="bitrue">Bitrue</option>
                                <option value="coinex">CoinEx</option>
                                <option value="hitbtc">Hitbtc</option>
                                {/* <option value="digifinex">digifinex</option> */}
                                {/* <option value="coinw">coinw</option> */}
                                <option value="bkex">Bkex</option>
                                <option value="whitebit">Whitebit</option>
                                {/* <option value="bitmart">bitmart</option> */}
                                {/* <option value="coindcx">coindcx</option> */}
                                <option value="bitget">Bitget</option>
                                <option value="kucoin">Kucoin</option>
                              </select>
                            }
                            {defaultExchangeType == "BTC" &&
                              <select class="form-control" onChange={e=>this.setState({exchange2:e.target.value})}>
                                <option value="coinex">CoinEx</option>
                                <option value="hitbtc">Hitbtc</option>
                                {/* <option value="digifinex">digifinex</option> */}
                                <option value="kucoin">Kucoin</option>
                              </select>
                            }
                            {defaultExchangeType == "ETH" &&
                              <select class="form-control" onChange={e=>this.setState({exchange2:e.target.value})}>
                                <option value="bitrue">Bitrue</option>
                                <option value="hitbtc">Hitbtc</option>
                                {/* <option value="digifinex">digifinex</option> */}
                                <option value="whitebit">Whitebit</option>
                                <option value="kucoin">Kucoin</option>
                              </select>
                            }
                            

                          {exchange2 == "kucoin"  || exchange2 == "bitget" ?
                            <div class="inputgroup">
                                <label></label>
                                <input type="text" class="form-control" placeholder="Enter API key" onChange={e=>this.setState({exchange2ApiKey:e.target.value})}/>
                                <input type="text" class="form-control" placeholder="Enter secret key"  onChange={e=>this.setState({exchange2Secret:e.target.value})}/>
                                <input type="text" class="form-control" placeholder="Enter Pass Phrase"  onChange={e=>this.setState({kuCoinPassphrase2:e.target.value})}/>
                            </div>
                             :
                             <div class="inputgroup">
                                   <label></label>
                                   <input type="text" class="form-control" placeholder="Enter API key" onChange={e=>this.setState({exchange2ApiKey:e.target.value})}/>
                                   <input type="text" class="form-control" placeholder="Enter secret key"  onChange={e=>this.setState({exchange2Secret:e.target.value})}/>
                             </div>
                           }

                           
                            {exchanges.map((data, index)=>{
                              const {exchange, apiKey, secret }= data;
                              return(
                              <div class="inputgroup" key={index}>
                                  <br/>
                                  <h5>Exchange {index + 3}</h5>
                                  <input type="text" class="form-control" placeholder="Exchange Name" name="exchange" value={exchange || ""} onChange={e => this.handleChange(index, e)}/>
                                  <input type="text" class="form-control" placeholder="Exchange Api Key" name="apiKey" value={apiKey || ""} onChange={e => this.handleChange(index, e)}/>
                                  <input type="text" class="form-control" placeholder="Exchange secret key" name="secret" value={secret || ""} onChange={e => this.handleChange(index, e)}/>
                                  {(exchange.length!==1)? <button onClick={this.removeFormFields}>Remove</button>:''}
                                  <br/>
                              </div>
                            )
                          })
                      }
                            <button onClick={this.addInputField}> Add more</button>
                        </div>

                        <div class="form-group">
                            <h5>Trade preferences</h5>
                            <div class="inputgroup">
                                <input type="text" class="form-control" placeholder="Amount to trade" onChange={e=>this.setState({amount:e.target.value})}/>
                                <h5>Number of trades per day</h5>
                                <select class="form-control" onChange={e=>this.setState({numberOfTradePerDay:e.target.value})}>
                                  <option value={300}>300</option>
                                  <option value={400}>400</option>
                                  <option value={700}>700</option>
                                  <option value={1000}>1000</option>
                                </select>
                            </div>
                            <br/>
                            <button onClick={()=>this.startBot()}>Start bot</button>
                            <br/>
                            <button onClick={()=>this.stop()}>Stop bot</button>
                        </div>
                </div>
                </div>
            </div>
        </section>
        {/* Content End */}
      </div>
    );
  }
}

const mapDispatchToProps = { saveExchange,toggleLoader,stopBot };

const mapStateToProps = ({ Auth }) => {
  let {  } = Auth;
  return {  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
