import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import "../TradingBot/index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import {Redirect} from 'react-router-dom';
import Navbar from "../../components/navbar";
import {getMarketBotHistory} from "../../store/actions/Auth";
import moment from "moment";
import Timer from "../resetProvider";

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      address: localStorage.getItem("publicAddress"),
    };
  }

  componentDidMount(){
    this.props.getMarketBotHistory();
  }


  render() {
    let { sticky } = this.state;
    let {userData,keys} = this.props;
    let token = localStorage.getItem('token');
    // const columns = [
    //   {
    //     id: "pair",
    //     Header: "Pair traded",
    //     accessor: (kycData) => (kycData["pair"] ? kycData['pair'] : "-"),
    //     filterable: true,
    //   },
    //   {
    //     id: "buyprice",
    //     Header: "Buy Price",
    //     accessor: (kycData) => (kycData["buyprice"] ? kycData['buyprice']: "-"),
    //     filterable: true,
    //   },
    //   {
    //     id: "sellprice",
    //     Header: "Sell Price",
    //     accessor: (kycData) => (kycData["sellprice"] ? kycData['sellprice'] : "-"),
    //     filterable: true,
    //   },
    //   {
    //     id: "ProfitLose",
    //     Header: "Profit/Lose Difference",
    //     accessor: (kycData) => (kycData["sellprice"] ? (Number(kycData['sellprice'])-Number(kycData['buyprice'])).toFixed(8): "-"),
    //     filterable: true,
    //   },
    //   {
    //     id: "Exchange",
    //     Header: "Exchange",
    //     accessor: (kycData) => (kycData["exchange"] ? kycData['exchange'] : "-"),
    //     filterable: true,
    //   },
    //   {
    //     id: "CreateOrder",
    //     Header: "Create Order",
    //     accessor: (kycData) => (kycData["createdAt"] ? moment(kycData['createdAt']).format('DD/MM/YYYY'): "-"),
    //     filterable: true,
    //   },
    // ];

    if(!token) return <Redirect to="/" />
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        <Navbar sticky={sticky} />
        <Timer />
        <section class="profit-loss">
            <div class="container">
                <div class="inner">
                    <h1>Profit/loss history</h1>
                    {/* {userData && userData.length > 0 && userData && userData.map(item=> */}
                    <div class="cards-wrap">
                        <div class="price-card">
                            <h3>Amount</h3>
                            {userData && userData.length > 0 && userData && userData.map(item=>
                            <p>{item['amount']}</p>
                            )}
                        </div>
                        <div class="price-card">
                            <h3>Symbol</h3>
                            {userData && userData.length > 0 && userData && userData.map(item=>
                            <p>{item['pair']}</p>
                            )}
                        </div>
                        <div class="price-card">
                            <h3>Exchange</h3>
                            {userData && userData.length > 0 && userData && userData.map(item=>
                            <p>{item['exchangeName']}</p>
                            )}
                        </div>
                        <div class="price-card">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                            <h3>Profit/loss in percentage</h3>
                            {userData && userData.length > 0 && userData && userData.map(item=>
                            <p>{item['buysellPercentage']}</p>
                            )}
                        </div>
                    </div>
                    {/* )} */}
                    <div class="trading-wrapper">
                        <div class="trading-inner">
                            <div class="trading-history">
                                <h2>Trading history</h2>
                                <ul>
                                    <li>Pair traded</li>
                                    {/* <li>Starting date/time</li> */}
                                    <li>Buy Price</li>
                                    <li>Sell Price</li>
                                    <li>Profit/Lose Difference</li>
                                    <li>Exchange</li>
                                </ul>
                                {/* <ReactTable
                                  className="trading-list"
                                  data={keys && keys.length > 0 ? keys : []}
                                  resolveData={(data) => data.map((row) => row)}
                                  columns={columns}
                                  minRows={20}
                                  filterable={true}
                                /> */}
                                <div class="history-values">
                                    
                                </div>
                            </div>
                            <div class="trading-list">
                                <ul>
                                  {keys && keys.length > 0 &&
                                    keys.map(items=>
                                        <li>
                                            <span class="tl-1">{items['pair']} <br/> {moment(items['createdAt']).format('LLLL')}</span>
                                            {/* <span >{moment(items['createdAt']).format('LLLL')}</span> */}
                                            <span class="tl-1">{items['buyPrice']}</span>
                                            <span class="tl-1">{items['sellPrice']}</span>
                                            <span class="tl-1"><strong>{(Number(items['sellPrice']) - Number(items['buyPrice'])).toFixed(6)}</strong></span>
                                            <span class="tl-1"><strong>{items['exchange']}</strong></span>
                                        </li>
                                        )                                    
                                    }

                             
                                </ul>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = {  getMarketBotHistory };

const mapStateToProps = ({ Auth }) => {
  let {setMarketBotHistory} = Auth;
  console.log("***************** get histopry",setMarketBotHistory)
  // let keys = [
  //   {
  //     pair:'XDC/UDST',
  //     buyprice: 0.000123112,
  //     sellprice: 100,
  //     exchange: "UBUIT",
  //     sellExchangeName: "KuCoin",
  //     createdAt:"2023-06-09T06:04:14.542+00:00"
  //   },
  //   {
  //     pair:'XDC/UDST',
  //     buyprice: 12,
  //     sellprice: 200,
  //     buyExchangeName: "Binance",
  //     exchange: "KuCoin",
  //     createdAt:"2024-06-09T06:04:14.542+00:00"
  //   }
  // ]
  // return {userData:setMarketBotHistory['dataUser'],keys};
  return {userData:setMarketBotHistory['dataUser'],keys:setMarketBotHistory['keys']};
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
