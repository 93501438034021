import { PURGE } from "redux-persist";

let initialState = {
  publicAddress: '',
  userStatus: false,
  styleButton: false,
  getExchangeData:[],
  setHistory: [],
  getMarketBot:[],
  setMarketBotHistory:[],
  setLoader: { message: 'Please Wait...', status: false },
  getMarketMakingBot:{}
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {

    case 'LOGIN':
      localStorage.setItem('publicAddress', payload);
      return {
        ...state,
        publicAddress: payload,
      };

    case 'LOGOUT':
      localStorage.removeItem('publicAddress');
      window.location.href = '/'
      return {
        ...state,
        publicAddress: '',
      };

    case 'TOGGLE_LOADER':
      return {
        ...state,
        setLoader: payload,
      };

    case 'SET_STYLE_BUTTON':
      return {
        ...state,
        styleButton: payload
      }

    case 'GET_SAVE_EXCHANGE':
      return {
        ...state,
        getExchangeData: payload
      }

    case 'SET_HISTORY':
      return {
        ...state,
        setHistory: payload
      }

    case 'GET_MARKET_BOT':
      return {
        ...state,
        getMarketBot: payload
      }

    case 'SET_MARKET_BOT_HISTORY':
        return {
          ...state,
          setMarketBotHistory: payload
        }
    
    case 'GET_MARKET_MAKING_BOT':
      return {
        ...state,
        getMarketMakingBot: payload
      }

    default:
      return state;
  }
};

export default Auth;