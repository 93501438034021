export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const logout = (data) => ({
  type: 'LOGOUT',
  payload: data,
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data,
});

export const toggleLoader = (data) => ({
  type: 'TOGGLE_LOADER',
  payload: data,
});

export const setStyleButtons = (data) => ({
  type: 'SET_STYLE_BUTTON',
  payload: data,
});


export const saveExchange = (data) => ({
  type: 'SAVE_EXCHANGE',
  payload: data,
});

export const getSaveExchange = (data) => ({
  type: 'GET_SAVE_EXCHANGE',
  payload: data,
});

export const getHistory = () =>({
  type: 'GET_HISTORY'
})

export const setHistory = (data) =>({
  type: 'SET_HISTORY',
  payload: data
})

export const stopBot = () =>({
  type: 'SET_STOP_BOT'
})


export const setMarketBot = (data) => ({
  type: 'SET_MARKET_BOT',
  payload: data,
});

export const getMarketBot = (data) => ({
  type: 'GET_MARKET_BOT',
  payload: data,
});

export const getMarketBotHistory = () =>({
  type: 'GET_MARKET_BOT_HISTORY'
})

export const setMarketBotHistory = (data) =>({
  type: 'SET_MARKET_BOT_HISTORY',
  payload: data
})

export const stopMarketBot = (data) =>({
  type: 'SET_MARKET_STOP_BOT',
  payload: data
})

export const getMarketMakingBot = (data) =>({
  type: 'GET_MARKET_MAKING_BOT',
  payload: data
})
