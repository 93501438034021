// import Sockets from './Sockets';
import { all, takeEvery } from 'redux-saga/effects';
import { login, logout, saveExchange, getHistroy,stopBot,setMarketBot,getMarketBotHistory,stopMarketBot } from './Auth';

export default function* rootSaga() {
  yield all([
    yield takeEvery('LOGIN', login),
    yield takeEvery('LOGOUT', logout),
    yield takeEvery('SAVE_EXCHANGE', saveExchange),
    yield takeEvery('GET_HISTORY', getHistroy),
    yield takeEvery('SET_STOP_BOT',stopBot),
    yield takeEvery('SET_MARKET_BOT',setMarketBot),
    yield takeEvery('GET_MARKET_BOT_HISTORY',getMarketBotHistory),
    yield takeEvery('SET_MARKET_STOP_BOT',stopMarketBot),

    // Sockets();
  ]);
}