import axios from 'axios';
import { web3 } from '../web3';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { setHeader } from "../axios";
import { all, takeEvery, call, put } from 'redux-saga/effects';

import { setToken,setAddress,setHistory,toggleLoader, setMarketBotHistory } from '../actions/Auth';

/*========== ASSESSMENT FUNCTIONS =============*/

export function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/loginUser', payload });
  if (error) {
    // yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    localStorage.setItem('token',response["data"]["body"]["token"]);
    const decoded = jwt_decode(response["data"]["body"]["token"]);
    if (decoded["role"] !== "admin") {
      EventBus.publish("error", "Can't login through User account ");
      return;
    }
    // yield put(setLoginData(true));
    // yield put(setToken(response["data"]["body"]["token"]))
    setAddress({publicAddress:payload['address']});
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("success", response['data']['message'])
    setTimeout(() => window.location.href = '/Dashboard', 1000);
  }
};
export function* logout() {
  yield put(setAddress(""));
};

export function* saveExchange({ payload }) {
  console.log("************* payload",payload);
  const { error, response } = yield call(postCall, { path: "/saveEchangeKeys", payload });
  if (error){
    yield put(toggleLoader({status:false}))
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    EventBus.publish("success", response['data']['message']);
  }
};

export function* getHistroy() {
  console.log("******************* histroy");
  const { error, response } = yield call(getCall,"/getAllkeys");
  console.log("******************* response",response);
  if (error) { EventBus.publish("error", error['response']['data']['message']); yield put(setHistory([])); }
  else if (response) {
    yield put(setHistory(response['data']['body']));
  }
};

export function* stopBot(){
  const { error, response } = yield call(getCall,"/stopBot");
  if (error){
    yield put(toggleLoader({status:false}))
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    EventBus.publish("success", response['data']['message']);
  }
}

export function* stopMarketBot({ payload }){
  const { error, response } = yield call(getCall,`/stopMarketingBot/${payload['exchange']}`);
  if (error){
    yield put(toggleLoader({status:false}))
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    EventBus.publish("success", response['data']['message']);
    yield put(setMarketBotHistory(response['data']['body']));
  }
}

export function* getMarketBotHistory() {
  const { error, response } = yield call(getCall,"/getAllMarketkeys");
  if (error) { EventBus.publish("error", error['response']['data']['message']); yield put(setMarketBotHistory([])); }
  else if (response) {
    yield put(setMarketBotHistory(response['data']['body']));
  }
};

export function* setMarketBot({ payload }) {
  const { error, response } = yield call(postCall, { path: "/saveMarketingBot", payload });
  if (error){
    yield put(toggleLoader({status:false}))
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    EventBus.publish("success", response['data']['message']);
    yield put(setMarketBotHistory(response['data']['body']));
  }
};

function* actionWatcher() {
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
