import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import {Redirect} from 'react-router-dom';
import "../TradingBot/index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import {setMarketBot} from "../../store/actions/Auth"
import Navbar from "../../components/navbar";
import {toggleLoader,stopMarketBot, getMarketBotHistory } from "../../store/actions/Auth";
import Timer from "../resetProvider";

class MarketMaking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      address: localStorage.getItem("publicAddress"),
      pair:"XDC/USDT",
      exchange:"bybit",
      exchangeApiKey:"",
      exchangeSecret:"",
      spread:"",
      amount:"",
      password:"",
      numberOfTradePerDay:1000,
      defaultExchangeType:"USDT",
      exchanges:[{exchange: 'bybit', apiKey: '', secret: '', password: ''}]
    };
  }

  componentDidMount(){
    // this.props.getMarketBotHistory();
  }

  changePairsData=(e)=>{
    this.setState({pair:e.target.value})
    if(e.target.value == "XDC/ETH"){
      this.setState({defaultExchangeType:"ETH",exchange:"bitrue"})
    }else if(e.target.value == "XDC/USDT"){
      this.setState({defaultExchangeType:"USDT",exchange:"coinex",exchanges:[{exchange: 'bybit', apiKey: '', secret: '', password: ''}]})
    }else if(e.target.value == "XDC/INR"){
      this.setState({defaultExchangeType:"INR",exchange:"coindcx",exchanges:[]})
    }
  }

  startBot =()=>{
    let {exchanges,pair,exchange,exchangeApiKey,exchangeSecret,amount,numberOfTradePerDay,spread,password} = this.state;

    // if(!amount || amount < 50) return EventBus.publish("error", "Amount must be grater then 50 XDC");
    if(!amount || amount < 39) return EventBus.publish("error", "Minimum Amount 40 XDC");
    if(!numberOfTradePerDay) return EventBus.publish("error", "Please Add Number Of Trade Per Day");

    if(exchange == 'coindcx'){
      exchanges = [{exchange,apiKey:exchangeApiKey,secret:exchangeSecret,password}];
      this.props.toggleLoader({message:"Verify Keys & Execute",status:true});
      this.props.setMarketBot({exchanges,pair,amount,numberOfTradePerDay,spread});
    }else{
      // exchanges = [{exchange,apiKey:exchangeApiKey,secret:exchangeSecret,password}];
      this.props.toggleLoader({message:"Verify Keys & Execute",status:true});
      this.props.setMarketBot({exchanges,pair,amount,numberOfTradePerDay,spread});
    }
   
  }


  handleChange = (i, e) => {
    let {exchanges} = this.state;
    let newFormValues = [...exchanges];
    newFormValues[i][e.target.name] = e.target.value;
 }

  addInputField = ()=>{
    let {exchanges} = this.state;
    if(exchanges.length >= 5) return EventBus.publish("error", "You can select maximum 5 exchanges");
    this.setState({exchanges:[...exchanges, {
        exchange:'bybit',
        apiKey:'',
        secret:'',
        password:''
    }]})
  }

  removeFormFields = (index)=>{
    let {exchanges} = this.state;
    const rows = [...exchanges];
    rows.splice(index, 1);
    this.setState({exchanges:rows})
  }

  handleChange = (index, evnt)=>{
    let {exchanges} = this.state;
    const { name, value } = evnt.target;
    const list = [...exchanges];
    list[index][name] = value;
    this.setState({exchanges:list})
}

 stop =(exchange) =>{
  this.props.toggleLoader({message:"Bot Stop Please Wait....",status:true});
  this.props.stopMarketBot({exchange});
 }

  render() {
    let { sticky,defaultExchangeType,exchange,exchanges } = this.state;
    let token = localStorage.getItem('token');
    if(!token) return <Redirect to="/" />
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        <Navbar sticky={sticky} />
        <Timer />
        {/* Content Start */}
        <section class="dashboard">
            <div class="container">
                <div class="inner">
                    <div className="form">
                        <div class="form-group">
                            <h5>Select trading pair</h5>
                            <select class="form-control" onChange={e=>this.changePairsData(e)}>
                            <option value="XDC/USDT">XDC/USDT</option>
                            <option value="XDC/INR">XDC/INR</option>
                            </select>
                        </div>

                        <div class="form-group">
                          <h5>Add Exchange </h5>
                          {defaultExchangeType == "INR" &&
                          <>
                            <select class="form-control" onChange={e=>this.setState({exchange:e.target.value})}>
                              <option value="coindcx">Coindcx</option>
                            </select>
                         
                            <div class="inputgroup">
                                  <label></label>
                                  <input type="text" class="form-control" placeholder="Enter API key" onChange={e=>this.setState({exchangeApiKey:e.target.value})}/>
                                  <input type="text" class="form-control" placeholder="Enter secret key"  onChange={e=>this.setState({exchangeSecret:e.target.value})}/>
                            </div>
                          </>
                        }


                        {defaultExchangeType == "USDT" &&

                            exchanges.map((data, index)=>{
                              const {exchange, apiKey, secret,password }= data;
                              return(
                              <div class="inputgroup" key={index}>
                                  <br/>
                                      <select class="form-control" name="exchange" value={exchange || ""} onChange={e => this.handleChange(index, e)}>
                                        <option value="bybit">Bybit</option>
                                        <option value="bitrue">Bitrue</option>
                                        <option value="coinex">CoinEx</option>
                                        <option value="gate">Gate</option>
                                        <option value="huobi">Huobi</option>
                                        <option value="hitbtc">Hitbtc</option>
                                        <option value="bitfinex">Bitfinex</option>
                                        <option value="xt">Xt.com</option>
                                        <option value="coindcx">Coindcx</option>
                                        <option value="bkex">Bkex</option>
                                        <option value="whitebit">Whitebit</option>
                                        <option value="bitmart">Bitmart</option>
                                        <option value="bitget">Bitget</option>
                                        <option value="kucoin">Kucoin</option>
                                      </select>

                                      {exchange == "kucoin"  || exchange == "bitget" ?
                                          <div class="inputgroup">
                                              <label></label>
                                              <input type="text" class="form-control" placeholder="Enter API key" name="apiKey" value={apiKey || ""} onChange={e => this.handleChange(index, e)}/>
                                              <input type="text" class="form-control" placeholder="Enter secret key"  name="secret" value={secret || ""} onChange={e => this.handleChange(index, e)}/>
                                              <input type="text" class="form-control" placeholder="Enter Pass Phrase"  name="password" value={password || ""} onChange={e => this.handleChange(index, e)}/>
                                          </div>
                                        :
                                        <div class="inputgroup">
                                              <label></label>
                                              <input type="text" class="form-control" placeholder="Enter API key" name="apiKey" value={apiKey || ""} onChange={e => this.handleChange(index, e)}/>
                                              <input type="text" class="form-control" placeholder="Enter secret key"  name="secret" value={secret || ""} onChange={e => this.handleChange(index, e)}/>
                                        </div>
                                      }

                                  {(exchange.length !==1) && index !== 0? <button onClick={()=>this.removeFormFields(index)}>Remove</button>:''}
                                  {/* {(exchanges.length > 1) ? <button onClick={this.removeFormFields}>Remove</button>:''} */}
                                  <br/>
                              </div>
                            )
                          })
                        }
                        {defaultExchangeType == "USDT" && <button onClick={this.addInputField}> Add more</button> }

                        </div>

                        <div class="form-group">
                            <h5>Trade preferences</h5>
                            <div class="inputgroup">
                                {/* <input type="text" class="form-control" placeholder="Spread" onChange={e=>this.setState({spread:e.target.value})}/> */}
                                <input type="text" class="form-control" placeholder="Amount" onChange={e=>this.setState({amount:e.target.value})}/>
                                <h5>Number of trades per day</h5>
                                <select class="form-control" onChange={e=>this.setState({numberOfTradePerDay:e.target.value})}>
                                  <option value={300}>300</option>
                                  <option value={400}>400</option>
                                  <option value={700}>700</option>
                                  <option value={1000}>1000</option>
                                </select>
                            </div>
                            <br/>
                            <button onClick={()=>this.startBot()}>Start bot</button>
                            <br/>
                            {this.props.userData && this.props.userData.length >= 0 && this.props.userData.map(item=>
                              <>
                                <button onClick={()=>this.stop(item['exchangeName'])}>Stop bot {item['exchangeName']}</button>
                                <br/>
                              </>
                              )}
                        </div>
                </div>
                </div>
            </div>
        </section>
        {/* Content End */}
      </div>
    );
  }
}

const mapDispatchToProps = { setMarketBot,toggleLoader,stopMarketBot,getMarketBotHistory };

const mapStateToProps = ({ Auth }) => {
  let {setMarketBotHistory,getStopMarketMakingBot} = Auth;
  return {userData:setMarketBotHistory['dataUser'],keys:setMarketBotHistory['keys']};
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketMaking);
