/* -- set app title --*/
const AppTitle = 'FRONTEND TRADING BOT';

/* -- set app mode -- */
const AppMode = ['production'];
// const AppMode = ['development'];

/* -- set API URLs --*/
const testing = 'https://server.xavbots.com';
const production = 'https://server.xavbots.com';
const development = 'http://localhost:4000';


let SocketUrl;
let env = AppMode[0] || 'development', networkId = '', message = '', explorer = '';

switch (AppMode[0]) {
  case 'development':
    networkId = 50;
    SocketUrl = development;
    explorer = 'https://apothem.blocksscan.io'
    message = 'Please switch your network to XDC Mainnet';
    break;
  case 'production':
    networkId = 50;
    SocketUrl = production;
    explorer = 'https://xdc.blocksscan.io'
    message = 'Please switch your network to XDC Mainnet';
    break;
  case 'testing':
    networkId = 50;
    SocketUrl = development;
    explorer = 'https://apothem.blocksscan.io'
    message = 'Please switch your network to XDC Mainnet';
    break;
  default:
    networkId = 51;
    SocketUrl = 'http://34.235.130.242:4000';
    explorer = 'https://apothem.blocksscan.io'
    message = 'Please switch your network to XDC Testnet';
}
let ApiUrl = `${SocketUrl}/api/users`;
export { AppTitle, ApiUrl, SocketUrl, networkId, message, explorer, env };